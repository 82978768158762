@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 40px;
  background-color: black;
  position: relative;
  color: white;
  max-width: 1200px;
  margin: auto;
}

.section-heading {
  font-size: 3.5em;
  margin: 20px 0;
  font-family: 'Kanit', sans-serif;
  font-weight: bolder;
  color: #b1a695;
  letter-spacing: 2px;
  align-self: flex-start;
  max-width: 1200px;
  line-height: 0.9;
}

.intro-paragraph {
  font-size: 1.5rem;
  font-family: 'Kanit', sans-serif;
  margin-bottom: 40px;
  max-width: 1100px;
  position: relative;
  padding: 0 50px;
  font-weight: 300;
  color: #eff4ee;
  text-transform: none;
}
.card-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  white-space: nowrap;
  justify-content: center;
}

.card {
  display: inline-block;
  position: relative;
  width: 250px;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin: 20px 0;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0);
  color: whitesmoke;
  backdrop-filter: blur(8px);
  text-align: center;
  transition: background 0.3s ease;
  font-family: 'Playfair Display', serif;
}

.card-info:hover {
  background: rgba(255, 255, 255, 0.7);
}

.card-info h3,
.card-info p {
  margin: 0;
}

.card-info h3 {
  font-size: 25px;
  margin-bottom: 5px;
  font-weight: 700;
}

.card-info p {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
}

@media (max-width: 1200px) {
  .card-container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    white-space: normal;
  }
  
    .section-heading {
      font-size: 3em;
      margin: 20px 0;
      font-family: 'Kanit', sans-serif;
      font-weight: bolder;
      color: #b1a695;
      letter-spacing: 2px;
      text-transform: uppercase;
      align-self: flex-start;
      max-width: 1200px;
      line-height: 0.9;
    }

  .card {
    width: 90%;
    max-width: 320px;
    display: block;
  }

  .card:hover {
    transform: scale(1.02);
  }

  .card-info {
    font-size: 14px;
  }

  .card-info h3 {
    font-size: 19px;
  }
}
