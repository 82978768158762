.navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5vw;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 10;
}

.navbar-logo {
  font-size: 1.5rem;
  color: #b1a695;
  font-weight: 800;
  padding: 5px 20px;
  display: flex;
  gap: 15px;
  font-family: 'Montserrat';
  letter-spacing: 1px;
}

.navbar-links {
  font-family: 'Poppins';
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  text-decoration: none;
  color: #afb5ae;
  padding: 10px 15px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.navbar-links a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.navbar-contact button {
  background-color: transparent;
  font-family: 'Montserrat';
  border: 2px solid #fff;
  padding: 10px;
  margin: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 3px #395b50, 0 0 6px #395b50, 0 0 9px #395b50,
    0 0 12px #395b50;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-contact button:hover {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 6px #395b50, 0 0 9px #395b50, 0 0 12px #395b50,
    0 0 15px #395b50;
}

.navbar-hamburger {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
}

/* Media Queries for Responsiveness */
@media (max-width: 775px) {
  .navbar-links {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    gap: 10px;
    display: none;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-hamburger {
    display: block;
  }

  .navbar-contact button {
    margin-top: 10px;
  }
}

/* @media (max-width: 480px) {
  .navbar-links {
    font-size: 0.9rem;
  }

  .navbar-contact button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
} */