.answer-container {
  display: flex;
  justify-content: center;
  color: #ffffff;
  padding: 30px;
  margin: 50px auto;
  display: flex;
}

.answer-container>.content {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  background-color: #0f0f0f;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
}

.question {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Poppins';
  text-decoration: underline;
  text-underline-offset: 3px;
}

.quote {
  font-size: 18px;
  line-height: 1.6;
  flex: 2;
  margin-right: 20px;
}

.profile {
  display: flex;
  align-items: center;
  flex: 1;
}

.avatar {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.profile-text p {
  margin: 0;
}

.name {
  font-weight: bold;
  font-size: 1.2rem;
}

.answer {
  font-size: 1.7rem;
  font-family: 'Kanit';
}

.nav-arrows {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 5px;
  cursor: pointer;
}

.nav-arrows * :hover {
  fill: #b1a695;
}


@media (max-width: 500px) {
  .answer {
    font-size: 1.2rem;
    font-family: 'Kanit';
  }
}