@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Montserrat:wght@400;600&family=Oswald:wght@400;500&family=Roboto:wght@400;500&display=swap');

:root {
  --dark-green-color: #1f2f16;
  --feldgrau-color: #395b50;
  --payne-color: #5a7684;
  --powder-blue-color: #92afd7;
  --lavender-color: #c5d1eb;
  --background-color: #f7f7f7;
  --form-background: #ffffff;
  --button-color: #395b50;
  /* Dark green */
  --button-hover-color: #1f2f16;
  /* Even darker green */
  --text-color: var(--dark-green-color);
  --text-light-color: var(--payne-color);
  --border-color: var(--feldgrau-color);
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-hover: 0 4px 15px rgba(0, 0, 0, 0.15);
  --transition-duration: 0.3s;
}

.form-section {
  max-width: 1200px;
  margin: auto;
  padding: 50px 40px;
}

.form-heading h1 {
  color: #b1a695;
  font-family: 'Kanit', sans-serif;
  font-size: 3.5em;
}

.contact-form-heading {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 3rem;
}

.contact-form-subheading {
  color: white;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-info {
  padding: 30px;
  border-radius: 3px;
  border-top: 5px solid #b3b096;
  box-shadow: var(--box-shadow);
  background-color: #222422;
  height: fit-content;
  margin: 20px;
}

.info h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #b3b096;
  font-weight: 600;
}

.info p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: 'Kanit';
  color: white;
}

.contact-details p {
  font-size: 1rem;
  margin: 5px 0;
  color: #b1a695;
}

.contact-form {
  padding-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  min-width: 45vw;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-size: 1.3rem;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-weight: 500;
  margin: 10px 0;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #222422;
  font-family: 'Roboto', sans-serif;
  color: white;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.contact-form button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: var(--button-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color var(--transition-duration) ease;
  font-family: 'Montserrat', sans-serif;
  margin: 10px 0;
}

.contact-form button:hover {
  background-color: var(--button-hover-color);
}

@media (width < 800px) {
  .content {
    flex-direction: column;
    padding: 0px;
  } 

  .form-section {
    padding: 30px;
  }
}