.carousel {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}


.carousel-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

@media screen and (max-width: 868px) {
    .carousel-image {
        background-size: contain;
    }
}

@media screen and (max-width: 520px) {
    .carousel {
        height: 45vh;
    }
}

@media screen and (min-width:521px)and (max-width: 750px) {
    .carousel {
        height: 65vh;
    }
}


.carousel-image.active {
    opacity: 1;
}