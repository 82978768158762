.footer {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid #ddd;
    text-align: center;
    background-color: #f9f9f9;
}

.footer-content {
    font-size: 14px;
    color: #333;
}