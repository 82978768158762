@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* List of fonts used:
   1. Merriweather - used for emphasis and special text
   2. Montserrat - used for headings (h1, h2, h3)
   3. Oswald - used for buttons and highlighted text
   4. Roboto - used for body text and secondary text
   5. Poppins
   6. Kanit
*/

:root {
  --dark-green-color: #1f2f16;
  --feldgrau-color: #395b50;
  --payne-color: #5a7684;
  --powder-blue-color: #92afd7;
  --lavender-color: #c5d1eb;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    /* background-color: #0f0f0f; */
    background-color: black;
}
